import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Container, useMediaQuery } from '@mui/material'

import { ASSETS_PRICES_QUERY } from 'shared/queries'

import NotebookDemo from './notebook_demo'
import SmartphoneDemo from './smartphone_demo'

import type { Theme } from '@mui/material'
import type { AssetsPricesData, AssetsPricesVars } from 'shared/queries'

const DemoSection = () => {
  const onLargeScreen = useMediaQuery((theme: Theme) => (theme.breakpoints.up('md')))

  const symbol = 'BTC'
  const range = '7d'

  const { loading, data } =
    useQuery<AssetsPricesData, AssetsPricesVars>(ASSETS_PRICES_QUERY, {
      variables: {
        quoteSymbol: 'USD',
        range,
      },
    })

  const usdPrices = data ? data.assetsPrices : []

  return (
    <Container
      maxWidth='md'
      sx={{
        minHeight: 'min(85vh, 500px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pb: 8,
        ...(!onLargeScreen && { pt: 4 }),
      }}
    >
      {(onLargeScreen) ? (
        <NotebookDemo
          loading={loading}
          symbol={symbol}
          usdPrices={usdPrices}
        />
      ) : (
        <SmartphoneDemo
          loading={loading}
          symbol={symbol}
          usdPrices={usdPrices}
        />
      )}
    </Container>
  )
}

export default DemoSection
